<!--用电行业情况分布-->
<template>
    <widget-framework v-if="dataLoaded" title="用电行业情况分布" :show-border="false" @click="$router.push('/business/customerAccount')">
        <template #content>
            <div class="container">
                <div class="sum">
                    <div class="userAmount">{{totalUserAmount}}</div>
                    <div class="capacity">
                        {{totalUserElectricity > 100000 ? (totalUserElectricity / 10000).toFixed(1) + '万' : totalUserElectricity}}
                        <span class="unit">kWH</span>
                    </div>
                </div>
                <v-chart :options="opt" :autoresize="true" class="chart"></v-chart>
                <div class="decoration6Container">
                    <dv-decoration-6 class="decoration6" :color="decorationColor"/>
                </div>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>
<script>
import WidgetBase from '../WidgetBase.vue';
import EchartBase from '../EchartBase';
import WidgetFramework from '@/components/widgets/WidgetFramework';
import numeral from 'numeral';
import {blue, white, lightGrey, grey, cyan, tooltip} from '../constant';

export default {
    name: 'DistributionElectricityIndustry',
    components: {
        WidgetFramework,
    },
    // 混合小部件组件基类
    mixins: [WidgetBase, EchartBase],
    data() {
        return {
            totalUserAmount: 0,
            totalUserElectricity: 0,
            decorationColor: [blue, blue],
            opt: {
                textStyle: {
                    color: lightGrey,
                },
                legend: {
                    textStyle: {
                        color: white,
                        fontSize: 14,
                    }
                },
                grid: {
                    bottom: '0',
                    top: '30',
                    left: '0',
                    right: '0',
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    position: this.setTooltipPosition,
                    axisPointer: {
                        type: 'shadow',
                        shadowStyle: {
                            color: cyan,
                            opacity: 0.2,
                        },
                    },
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText,
                    formatter(params) {
                        const {elecTypeName, userAmount, userAmountPercent, userElectricity, userElectricityPercent} = params[0].data;
                        return `${elecTypeName}<br/>
                           用户数量：${userAmount || 0}<br/>
                           用户占比：${numeral(userAmountPercent).format('0%')}<br/>
                           用电总量：${userElectricity || 0}kWH<br/>
                           用电占比：${numeral(userElectricityPercent).format('0%')}<br/>`;
                    },
                },
                dataset: {
                    source: [],
                },
                xAxis: {type: 'value', show: false, max: 1},
                yAxis: {
                    type: 'category',
                    axisLine: {show: false},
                    axisTick: {show: false},
                    inverse: true,
                },
                series: [
                    {
                        type: 'bar',
                        name: '总用户数',
                        id: 'userAmountPercent',
                        itemStyle: {
                            color: blue,
                            barBorderRadius: 15,
                        },
                        barWidth: 13,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'transparent',
                            barBorderRadius: 15,
                            borderColor: grey,
                            borderWidth: 1,
                            shadowColor: grey,
                            shadowBlur: 2,
                        },
                        label: {
                            normal: {
                                position: 'right',
                                show: true,
                                formatter(param) {
                                    const {userAmountPercent, userAmount} = param.data;
                                    return `{a|${numeral(userAmountPercent || 0).format('0%')}}{b|(${userAmount || 0})}`;
                                },
                                rich: {
                                    a: {
                                        color: white,
                                        fontWeight: 'bold',
                                    },
                                    b: {
                                        color: blue,
                                    },
                                },
                            },
                        },
                        encode: {
                            x: 'userAmountPercent',
                            y: 'elecTypeName',
                        },
                    },
                    {
                        type: 'bar',
                        name: '总用电量',
                        id: 'userElectricityPercent',
                        itemStyle: {
                            color: cyan,
                            barBorderRadius: 15,
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: 'transparent',
                            barBorderRadius: 15,
                            borderColor: grey,
                            borderWidth: 1,
                            shadowColor: grey,
                            shadowBlur: 2,
                        },
                        barGap: '50%',
                        barWidth: 13,
                        barCategoryGap: '50%',
                        label: {
                            position: 'right',
                            show: true,
                            formatter(param) {
                                const {userElectricityPercent, userElectricity} = param.data;
                                return `{a|${numeral(userElectricityPercent || 0).format('0%')}}{b|(${userElectricity || 0}kWH)}`;
                            },
                            rich: {
                                a: {
                                    color: white,
                                    fontWeight: 'bold',
                                },
                                b: {
                                    color: cyan,
                                },
                            },
                        },
                        encode: {
                            x: 'userElectricityPercent',
                            y: 'elecTypeName',
                        },
                    },
                ],
            },
            dataLoaded: false,
        };
    },
    mounted() {
        this.$client.listDistributionElectricityIndustry().then((ret) => {
            const {data = []} = ret || {};
            const totalUserAmount = data.reduce((total, cur) => total + (cur.userAmount || 0), 0);
            const totalUserElectricity = data.reduce((total, cur) => total + (cur.userElectricity || 0), 0);
            data.forEach((item) => {
                if (totalUserAmount) {
                    item.userAmountPercent = 1.0 * (item.userAmount || 0) / totalUserAmount;
                }
                if (totalUserElectricity) {
                    item.userElectricityPercent = 1.0 * (item.userElectricity || 0) / totalUserElectricity;
                }
            });

            this.opt.dataset.source = data || [];
            this.totalUserAmount = totalUserAmount;
            this.totalUserElectricity = totalUserElectricity;
            this.dataLoaded = true;
        });
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sum {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-top: 15px;

    .userAmount {
        font-size: 22px;
        color: @yellow;
        text-align: center;
        position: fixed;
        right: 50%;
        margin-right: 20px;

        .unit {
            font-size: 14px;
        }
    }

    .capacity {
        font-size: 22px;
        color: @yellow;
        text-align: center;
        position: fixed;
        left: 50%;

        .unit {
            font-size: 14px;
        }
    }
}

.chart {
    width: 100%;
    flex-grow: 1;
    //height: calc(100% - 60px);
}

.decoration6Container {
    width: 100%;
    height: 20px;

    .decoration6 {
        width: 150px;
        height: 25px;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
    }
}

</style>
